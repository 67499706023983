/** @format */

import React from "react";
import { MenuItem } from "@mui/material";
import { IoMdCall } from "react-icons/io";
import { MdMessage } from "react-icons/md";
import { BsWhatsapp } from "react-icons/bs";
import RightClickMenu from "../lead/RightClickMenu";
import useClickToCall from "./useClickToCall";
import { toast } from "react-toastify";
import useSendSMS from "./useSendSMS";
import DeleteDialog from "../utils/deleteDialog";
import useTwilioVoiceCall from "./useTwilioVoiceCall";

const PhoneMenu = ({ name, phNum, removeWhatsapp }) => {
  const {
    makeCall,
    endCall,
    callDuration,
    refetch: tokenRefetch,
  } = useTwilioVoiceCall(phNum);
  const { mutate } = useClickToCall(phNum);
  const { refetch: smsRefetch } = useSendSMS(phNum);

  const handleCall = () => {
    const number = phNum.replace(/(?!^\+)\D/g, "");
    console.log("call request on :" + number + "as reciever <--------------");
    makeCall(number);
    tokenRefetch();
  };

  return (
    <RightClickMenu name={name} isPhNum={true}>
      <DeleteDialog
        getApiRefetch={handleCall}
        element={
          <MenuItem sx={{ fontSize: "13px" }}>
            <IoMdCall className='me-2' /> Call Now
          </MenuItem>
        }
        description='This will make a phone call on their primary number.'
        anotherAction='Call'
      />
      {/* <MenuItem sx={{ fontSize: "13px" }}>
        <IoMdCall className="me-2" /> Call Now
      </MenuItem> */}
      <MenuItem
        onClick={() => {
          toast.promise(() => smsRefetch(), {
            pending: "pending",
            success: "SMS sent!",
            error: "Sorry, something went wrong. Try again later.",
          });
        }}
        sx={{ fontSize: "13px" }}>
        <MdMessage className='me-2' /> Message
      </MenuItem>
      {!removeWhatsapp && (
        <MenuItem onClick={() => {}} sx={{ fontSize: "13px" }}>
          <BsWhatsapp className='me-2' /> Whatsapp
        </MenuItem>
      )}
    </RightClickMenu>
  );
};

export default PhoneMenu;
