/** @format */

import React from "react";

import { Route, Routes } from "react-router-dom";
import Navbar from "../../../components/Layout/navbar";

import PrivateRoute from "../../../utils/PrivateRoute";

import SingleTask from "../singleTask";
import TaskTable from "../taskTable/index";

const Task = () => {
  return (
    <PrivateRoute>
      <Navbar />
      <Routes>
        <Route path='/' element={<TaskTable />} />
        <Route path='/:uuid' element={<SingleTask />} />
      </Routes>
    </PrivateRoute>
  );
};

export default Task;
