/** @format */

import React, { useEffect, useState, useRef } from "react";
import Container from "../../../components/Layout/Container";
import Header from "../../../components/Layout/Header";
import Table from "../../../components/Layout/table";
import useContactTable from "./useContactTable";
import { useDispatch } from "react-redux";
import { openContactDialog } from "../../../redux/actions/LayoutAction";
import { leadAndContactColumns } from "../../../constants/columns";
import useContactData from "../contactHooks/useContactData";

const ContactTable = () => {
  const { createColumnSchema } = useContactTable();
  const [contactData, setContactData] = useState([]);
  const { isLoading } = useContactData({
    onSuccess: (data) => setContactData(data?.data),
  });

  const dispatch = useDispatch();
  const tableRef = useRef();

  const handleClickOpen = () => {
    dispatch(openContactDialog());
  };

  const handleExportCSV = () => {
    console.log(tableRef);
    if (tableRef.current) {
      tableRef.current.exportCSV();
    }
  };

  useEffect(() => {
    document.title = "All Contacts | CRM";
  }, []);

  return (
    <>
      <Header
        name='Contacts'
        showSearch={true}
        exportButton
        exportOnClick={handleExportCSV}
        addBtnClick={handleClickOpen}
      />
      <Container sx={{ marginTop: "30px" }}>
        <Table
          ref={tableRef}
          mt={"30px"}
          title='contacts'
          data={contactData || []}
          loading={isLoading}
          columns={
            contactData && contactData[0]
              ? createColumnSchema(contactData[0])
              : leadAndContactColumns
          }
        />
      </Container>
    </>
  );
};

export default ContactTable;
