/** @format */

import { Grid, Typography } from "@mui/material";
import React from "react";
import { AiOutlineMail } from "react-icons/ai";
import { MdOutlinePhone } from "react-icons/md";
import { Link } from "react-router-dom";
import PhoneMenu from "../../../../ivr/PhoneMenu";

const ContactAccordion = ({ data }) => {
  return (
    <Grid container className='mb-3'>
      <Grid item xs={12} sm={12} md={12} className=' d-flex align-items-center'>
        <Grid item xs={2} sm={2} md={3} lg={4}>
          <Link to={`/contacts/${data?.uuid}`}>
            <Typography
              style={{ fontWeight: 500, color: "#000" }}
              fontSize={"13px"}
              component='p'
              className='d-flex'
            >
              {`${data?.first_name} ${data?.last_name}`}
            </Typography>
          </Link>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={"12px"} component='p' className='text-truncate'>
            {data?.title} &nbsp;-&nbsp;
            {data?.company_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={12} md={12} className='mt-3'>
        <Grid item xs={12} sm={12} md={12} className=' mb-2'>
          <Typography
            style={{
              border: "2px solid #274C77",
              borderRadius: "5px",
              color: "#274C77",
            }}
            fontSize={"12px"}
            component='p'
            className='px-2 py-1'
          >
            <span className='me-2'>
              <AiOutlineMail />
            </span>
            {data?.primary_email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} className=''>
          <PhoneMenu
            name={
              <Typography
                style={{
                  border: "2px solid #274C77",
                  borderRadius: "5px",
                  color: "#274C77",
                  cursor: "pointer",
                }}
                fontSize={"12px"}
                component='p'
                className='px-2 py-1'
              >
                <span className='me-1'>
                  <MdOutlinePhone />
                </span>{" "}
                {data?.primary_number}
              </Typography>
            }
            phNum={data?.primary_number}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactAccordion;
