/** @format */

import { useQuery } from "react-query";
import { tenantAPI } from "../../../config/api";

const getSingleTask = async (args) => {
  return await tenantAPI.get(`/task/${args}/`);
};

const useGetSingleTask = (id, options = {}) => {
  return useQuery(["task", id], () => getSingleTask(id), options);
};

export default useGetSingleTask;
