/** @format */

import React from "react";

import { Box, Grid, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import Breadcrumb from "../formsUI/Breadcrumb";
import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";

const AccountHeader = () => {
  const { account } = useSelector((state) => state.accountStore);

  return (
    <Grid
      container
      display='row'
      className='bg-fifth'
      sx={{
        borderBottom: "2px solid #ced4da",
        py: "10px",
        paddingLeft: "25px",
      }}
    >
      <Box sx={{ width: "100%" }} className='d-flex align-items-center'>
        <Grid className='d-flex align-items-center justify-content-center'>
          <IconButton>
            <Link
              to='/accounts'
              style={{ textDecoration: "none", color: "#000000" }}
            >
              <BiArrowBack />
            </Link>
          </IconButton>
        </Grid>
        <Grid
          container
          className='d-flex align-items-center justify-content-between ms-3'
        >
          <Grid>
            <Breadcrumb module='Accounts' name={account?.first_name} />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};

export default AccountHeader;
